import React, { Component } from "react";
import "./Header.scss";
import HeaderNav from "../headerNav/HeaderNav";
import { ButtonBlack, ButtonRed } from "../../Globals";
import { styled, alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import MenuDrawer from "../menuDrawer/MenuDrawer";

export default class PageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _menuDrawer: React.createRef(),
      anchorEl: null,
    };
  }
  async componentDidMount() {}
  openContactUs = () => {
    window.location.href = `/ContactUs`;
  };
  openOurMission = () => {
    window.location.href = `/OurMission`;
  };
  openHome = () => {
    window.location.href = `/`;
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const anchorEl = this.state.anchorEl;
    const open = Boolean(anchorEl);
    const navDisplay = this.props.navDisplay;
    return (
      <div className="Header">
        <div className="container">
          <div className="menu-icon">
            <IconButton onClick={this.props.menuClick}>
              {navDisplay ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
            </IconButton>
          </div>
          <img
            className="logo"
            src="assets/images/cropped-cropped-chugo_color.png"
            onClick={this.openHome}
          />
          <div className="menu">
            <div className="menu-dropdown">
              <ButtonBlack
                className="burgerNavs"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="text"
                disableElevation
                onClick={this.handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Our Brands
              </ButtonBlack>
              <HeaderNav
                componentProps={{
                  anchorEl: anchorEl,
                  open: open,
                  handleItemClick: this.props.handleItemClick,
                  handleClose: this.handleClose,
                  handleClick: this.props.handleClick,
                }}
              />
            </div>
            <div className="menu-text">
              <ButtonBlack className="burgerNavs" onClick={this.openOurMission}>
                Our Mission
              </ButtonBlack>
            </div>
            <div className="menu-text contactUs">
              <ButtonRed
                className="burgerNavs"
                variant="contained"
                color="secondary"
                onClick={this.openContactUs}
              >
                Contact Us
              </ButtonRed>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import WorkOutline from "@mui/icons-material/WorkOutline";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Grid from "@mui/material/Grid";

export default {
  userdata: {},
};

export const GlobalContext = React.createContext([]);

export const ButtonBlack = styled(Button)(({ theme }) => ({
  color: "black",
  textTransform: "capitalize",
  borderRadius: 20,
}));

export const ButtonRed = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#FF5666"),
  backgroundColor: "#FF5666",
  "&:hover": {
    backgroundColor: "#FF5666",
  },
  textTransform: "capitalize",
  borderRadius: 20,
}));

export const ButtonBlue = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#2964F5"),
  backgroundColor: "#2964F5",
  "&:hover": {
    backgroundColor: "#2964F5",
  },
  textTransform: "capitalize",
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  flex: 1,
  justifyContent: "center",
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
}));

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginLeft: 110,
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

import BasePageComponent from "../../components/BasePageComponent";
import { GridContainer } from "../../Globals";
import { Typography, Box, Button, Grid, Card, CardContent } from "@mui/material";
import "./OurMission.scss";

export default class OurMission extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", () => {
      this.setState({
        width: window.innerWidth,
      });
    });
  }
  handleContactUs = () => {
    window.location.href = "/ContactUs";
  };

  render() {
    const width = this.state.width;
    return super.render(
      <>
        <div className="ourMission">
          <div className="container">
            <div className="logocontain">
              <img className="logo" src="assets\images\cropped-Favicon.jpg"></img>
            </div>

            <div className="chugo-mission">
              <Typography variant="h4" className="mission_sec_title">
                CHuGO's Mission
              </Typography>
              <Typography variant="h1" className="mission_gradient_text">
                ChopeShift Human Global Organiser
              </Typography>
              <div className="mission_para_contain">
                <Typography className="mission_para">
                  Our mission is to provide extensive and all-inclusive people management and
                  <br /> career services that cater to people from all walks of life.
                </Typography>
              </div>
            </div>

            <div className="mission_card_container">
              <Card className="mission_card">
                <CardContent className="card_holder">
                  <Typography variant="h4" className="mission_card_title">
                    Jobseekers
                  </Typography>
                  <Typography className="mission_card_para">
                    Effortlessly find jobs from trusted companies with just one click. Let us help
                    you find your perfect job match!
                  </Typography>
                </CardContent>
              </Card>

              <Card className="mission_card">
                <CardContent className="card_holder">
                  <Typography variant="h4" className="mission_card_title">
                    Business Owners
                  </Typography>
                  <Typography className="mission_card_para">
                    Why sieve through endless part-time job applications, when you can hire trained
                    part-timers quickly and efficiently?
                  </Typography>
                </CardContent>
              </Card>

              <Card className="mission_card">
                <CardContent className="card_holder">
                  <Typography variant="h4" className="mission_card_title">
                    HR Personnels
                  </Typography>
                  <Typography className="mission_card_para">
                    Streamline and achieve frictionless backend processes such as managing staff
                    shifts, processing leaves, and more.
                  </Typography>
                </CardContent>
              </Card>
            </div>

            <div className="howOurMission">
              <Typography variant="h2" className="mission_how_title">
                How Our
                <span className="mission_how_gradient_title"> Mission </span>
                Began
              </Typography>

              <Box className="mission_sec3_contain_content">
                <Grid container spacing={2} className="mission_sec3_grid_container reverse">
                  <GridContainer item xs={6} className="mission_sec3_content_holder">
                    <Typography variant="h5" className="mission_sec3_title">
                      It all began as a simple spreadsheet...
                    </Typography>
                    <Typography className="mission_sec3_para">
                      CHuGO began as a simple spreadsheet in in the HR department of Challenger
                      Technologies Limited to manage the existing staff across both retail stores
                      and the back office. As more staff joined and the company achieved greater
                      heights, a simple spreadsheet couldn’t cut it anymore.
                    </Typography>
                  </GridContainer>
                  <Grid item xs={6} className="sec3_spreadsheet">
                    {width <= 767 ? (
                      <img
                        src="../../../../assets/images/mobile-3d-illustration-spreadsheet.png"
                        className="sec3_image"
                      />
                    ) : (
                      <img
                        src="../../../../assets/images/3d-illustration-spreadsheet-compressed.png"
                        className="sec3_image"
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>

              <Box className="mission_sec3_contain_content">
                <Grid container spacing={2} className="mission_sec3_grid_container">
                  <Grid item xs={6} className="sec3_monitor">
                    {width <= 767 ? (
                      <img
                        src="../../../../assets/images/mobile-3d-illustration-monitor.png"
                        className="sec3_image"
                      />
                    ) : (
                      <img
                        src="../../../../assets/images/3d-illustration-monitor-compressed.png"
                        className="sec3_image"
                      />
                    )}
                  </Grid>
                  <GridContainer item xs={6} className="mission_sec3_content_holder">
                    <Typography variant="h5" className="mission_sec3_title">
                      A software to better manage our HR processes and manpower was needed.
                    </Typography>
                    <Typography className="mission_sec3_para">
                      One that is unique to the rigorous and changing requirements of a service
                      industry. One that manages schedules, the different shifts over different
                      outlets, the different types of jobs and different pay rates. There wasn’t any
                      available commercially off the shelf that is affordable yet customisable. With
                      a modest budget and unique requirements, we decided to build it ourselves,
                      thus beginning our journey to bridge the gap between employees and employers.
                    </Typography>
                  </GridContainer>
                </Grid>
              </Box>

              <Box className="mission_sec3_contain_content">
                <Grid container spacing={2} className="mission_sec3_grid_container reverse">
                  <GridContainer item xs={6} className="mission_sec3_content_holder">
                    <Typography variant="h5" className="mission_sec3_title">
                      Today, CHuGO has gone above and beyond.
                    </Typography>
                    <Typography className="mission_sec3_para">
                      Developing a robust suite of brands that cater to all our needs – and yours
                      too. With highly customisable features and our dedication to ensuring our
                      clients’ success, we are sure that our offerings can exceed expectations and
                      add value to your life.
                    </Typography>
                  </GridContainer>
                  <Grid item xs={6} className="sec3_hat">
                    {width <= 767 ? (
                      <img
                        src="../../../../assets/images/mobile-3d-illustration-magician-hat.png"
                        className="sec3_image"
                      />
                    ) : (
                      <img
                        src="../../../../assets/images/3d-illustration-magician-hat-compressed.png"
                        className="sec3_image"
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </div>

            <div className="mission_GetInTouch">
              <div className="mission_container">
                <Box className="mission_getInTouchHolder">
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      backgroundColor: "transparent",
                      backgroundImage: "linear-gradient(90deg,#4900ff 0%,#af99ff 100%)",
                      borderRadius: 10,
                    }}
                    className="mission_sec4_banner"
                  >
                    <GridContainer item xs={6} className="mission_sec4_content_holder">
                      <Typography
                        variant="h5"
                        className="mission_sec4_title"
                        sx={{
                          fontSize: 43,
                          lineHeight: "49px",
                          color: "#ffffff",
                        }}
                      >
                        Get In Touch with Us!
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: 22,
                          lineHeight: "28px",
                          color: "#ffffff",
                          marginBottom: 5,
                        }}
                        className="mission_gettouch_para"
                      >
                        Does our mission match your goals? Drop us a message to discuss how we can
                        work together!
                      </Typography>
                      <Button
                        onClick={this.handleContactUs}
                        sx={{
                          backgroundColor: "transparent",
                          backgroundImage: "linear-gradient(90deg,#ec4067 15%,#ffc300 100%)",
                          color: "#ffffff",
                          borderRadius: 5,
                          padding: 1.4,
                        }}
                        className="mission_btnContactUs"
                      >
                        Contact Us
                      </Button>
                    </GridContainer>
                    <Grid item xs={6} className="mission_sec4_image">
                      <img
                        src="../../../../assets/images/rocket-illustration-compressed.png"
                        className="mission_getInTouchImg"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>

            <div className="mission_brands">
              <Typography className="mission_brand_title">Discover Our Brands</Typography>
              <div className="mission_logos">
                <a className="logoLinks" href="https://www.chopeshift.sg">
                  <img src="/assets/images/chopeshift-logo.png" width="150" />
                </a>
                <a className="logoLinks" href="https://lexisjobs.asia">
                  <img src="/assets/images/Lexisjobs-logo.png" width="150" />
                </a>
                <a className="logoLinks" href="https://www.lexiscard.asia">
                  <img src="/assets/images/Lexiscard_logo.png" width="150" />
                </a>
                <img className="logoLinks" src="/assets/images/cropped-cropped-chugo_color.png" width="150" />
                <img className="logoLinks" src="/assets/images/chopeboss-svg.svg" width="150" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

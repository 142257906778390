import { Switch, Route } from "react-router-dom";
import { Home, TermsConditions, PrivacyPolicy, OurMission, ContactUs } from "./pages";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route path="/OurMission">
        <OurMission />
      </Route>

      <Route path="/TermsConditions">
        <TermsConditions />
      </Route>

      <Route path="/PrivacyPolicy">
        <PrivacyPolicy />
      </Route>

      <Route exact path="/ContactUs">
        <ContactUs />
      </Route>
    </Switch>
  );
}

import BasePageComponent from "../../components/BasePageComponent";
import "./TermsConditions.scss";
import { Container, Typography } from "@mui/material";

export default class TermsConditions extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return super.render(
      <div className="terms_condition">
        <div className="container">
          <Typography className="terms-header">Terms & Conditions</Typography>

          <Typography className="terms-sub-head">
            Chopeshift & CHuGO Services
          </Typography>
          <Typography className="terms-sub-head2">
            Terms & Conditions on use of Chopeshift and CHuGO Websites and
            Mobile Applications
          </Typography>

          <Typography className="terms-sub-head2">About</Typography>
          <Typography className="terms-para">
            This policy sets out to explain the how and why Chopeshift & CHuGO
            (“Chopeshift” “CHuGO” “we” or “us”) collects, uses, and shares
            information about you when you use our Chopeshift & CHuGO websites,
            mobile apps, feedback system, widgets, systems and other online
            products and services (collectively, the “Services”) or when you
            otherwise interact with us or receive a communication from us. These
            terms and conditions apply to all our Services offered to our
            Chopeshift employees, employers and external customers.
          </Typography>

          <Typography className="terms-sub-head2">
            Disclaimer and Terms of Use
          </Typography>
          <Typography className="terms-para">
            This section contains the disclaimers and terms of use applicable to
            the use of Chopeshift and CHuGO websites and mobile applications
            (hereby known as the “Services”), governed by the rules and
            regulations of Challenger Technologies Limited (hereby known as the
            “Company”). By accessing these Services and any of the pages/links,
            you are representing that you have understood and accepted the
            disclaimers and the terms of use set out herein. If you do not
            accept the terms of use set out herein, you should not access these
            Services.
          </Typography>

          <Typography className="terms-sub-head2">
            Intellectual Property
          </Typography>
          <Typography className="terms-para">
            All copyright, patent, intellectual and other property rights in
            information contained herein is owned by the Company. No rights of
            any kind are licensed or assigned or shall otherwise pass to persons
            accessing such information. Under no circumstances should
            information contained herein or any part of it be copied, reproduced
            or redistributed. Any and all personal data collected by the Company
            from time to time may be used and disclosed for such purposes and to
            such persons in accordance with the Company's privacy policy
            statement.
          </Typography>

          <Typography className="terms-sub-head2">
            Third Party Content
          </Typography>
          <Typography className="terms-para">
            The Company does not endorse and is not responsible for the content
            of any site owned by any third party that may be linked to this
            website via hyperlink, whether such hyperlink is provided by the
            Company or by a third party. No one is authorized to link to any
            websites of the Company without prior approval from the Company.
          </Typography>

          <Typography className="terms-sub-head2">
            International Use{" "}
          </Typography>
          <Typography className="terms-para">
            Due to the global nature of the Internet, you agree to comply with
            all local rules regarding online conduct, including all laws, rules,
            codes, and regulations of the country in which you reside and the
            country from which you access this website. In addition, you agree
            to comply with all applicable laws, rules, codes, and regulations
            regarding the transmission of technical data exported from your
            jurisdiction.
          </Typography>

          <Typography className="terms-sub-head2">Systems</Typography>
          <Typography className="terms-para">
            These Services and the Internet in general may be subject to system
            delays and failures beyond our reasonable control. As such, you may
            have difficulty accessing this website. The Company shall not be
            held liable for any damages, losses or expenses of any kind, whether
            direct, indirect, special, consequential or incidental, arising out
            of or in connection with your access to, use of or browsing on the
            website. In this regard, all warranties or representations of any
            kind, whether expressed or implied by law, equity or statutes, are
            excluded to the extent permissible under the applicable law.
          </Typography>

          <Typography className="terms-sub-head2">Privacy Policy</Typography>
          <Typography className="terms-para">
            We might update this Privacy Policy from time to time. When we have
            to do this the revised policy will be posted on this page. You
            should check this page from time to time to review any changes we
            have made.
          </Typography>

          <Typography className="terms-sub-head2">What We Collect</Typography>
          <Typography className="terms-para">
            The Company collects and updates personal data (including, without
            limitation, name, address, e-mail address, telephone and fax
            numbers) from you from time to time. The Company may collect
            personal data when you submit feedback, comments or other
            correspondence to the Company through these Services or otherwise.
            Additionally, the Company may collect personal data through these
            Services by using cookies, clicktrails or other methods of
            monitoring web usage. If you do not want to receive a cookie from
            this website, you may set your browser to refuse cookies or to
            notify you when you receive a cookie, which you may then refuse upon
            such notice. However, by disabling cookies, you may not be able to
            take full advantage of this website.
          </Typography>

          <Typography className="terms-text-big">
            Information you provide to us
          </Typography>
          <Typography className="terms-para">
            We collect information you provide to us directly when you use the
            Services. This includes:
            <br />
            <br />
            <span className="terms-italic">Account Information.</span> To create
            an account, a username and password is provided by the Chopeshift
            and/or CHuGO system/s. An email address is provided by you upon
            registration. We also store your user account preferences and
            settings.
            <br />
            <br />
            <span className="terms-italic">Content you submit.</span> We collect
            the content you submit to the Services. This includes your profile
            photo, feedback, comments, photos and posts including saved drafts,
            and your reports and other communications. Your content may include
            texts, links, images, gifs, and videos.
            <br />
            <br />
            <span className="terms-italic">Transactional information.</span> If
            you purchase products or services from us (e.g., Hachi.tech or
            Chopeshift for employers or CHuGO) or sign up as a potential
            advertiser on Chopeshift Ads, we will collect certain information
            from you, including your name, address email address and information
            about the product or service you are purchasing. Payments are
            processed by third-party payment processors (e.g. PayPal and
            PayNow), please refer to the applicable processor's term's and
            privacy policy for more details on how your information is processed
            and stored.
            <br />
            <br />
            <span className="italic">Other information.</span> You may be
            required to provide other information directly to us. E.g., we may
            collect information when you fill in a form or a survey, participate
            in Challenger sponsored activities, or promotions, apply for a job,
            request for customer support or otherwise communicate with us.
          </Typography>

          <Typography className="terms-text-big">
            Information we collect automatically
          </Typography>
          <Typography className="terms-para">
            When you access or use our services, we may also automatically
            collect information about you. This includes:
            <br />
            <br />
            <span className="terms-italic">Log and use of data.</span> We may
            log information when you access and use the services. This may
            include your IP address, user-agent string, browser-type, operating
            system, referral URLs, device information (e.g. device information),
            pages visited, inks clicked, the requested URL, hardware settings,
            and search terms. This includes IP addresses used to create your
            account.
            <br />
            <br />
            <span className="terms-italic">
              Information collected from cookies and similar technologies.
            </span>{" "}
            We may receive information from cookies, which are pieces of data
            your browser stores and sends back to us when making requests, and
            similar technologies. We use this information to improve your
            experience, understand user activity, personalize content and
            advertisements, and improve the quality of our Services. This
            includes storing and retrieving information about your preferred
            settings in your account.
            <br />
            <br />
            <span className="terms-italic">Location information.</span> We may
            receive and process information about your location. E.g. We may
            collect information about the specific location of your mobile
            device by using GPS or Bluetooth. We may also receive location
            information from you when you choose to share such information on
            our Services, including associating your content with a location, or
            we may derive your approximate location from other information about
            you, including your IP address.
          </Typography>

          <Typography className="terms-text-big">
            Information Collected from Other Sources
          </Typography>
          <Typography className="terms-para">
            We may receive information about you from other sources, including
            from other users and third parties, and combine that information
            with other information we have about you. E.g. Demographic
            information, facial recognition or interest information about you
            from third parties, including advertisers, and combine it with our
            own data using a common account identifier such as a hash of an
            email address or a mobile-device ID.
            <br />
            <br />
            <span className="terms-italic">Linked Services.</span> If you
            authorize or link other services (e.g. third-party apps or websites)
            to access your ChopeShift account, ChopeShift receives information
            about your use of that service when it uses that authorization.
            Linking services may also cause other services to send us
            information about your account with those services.
            <br />
            <br />
            <span className="terms-italic">
              Information collected from integration.
            </span>{" "}
            We may also receive information about you, including log and usage
            data and cookie information, from third-party websites that
            integrate our Services, including our embeds, buttons, advertising
            technology and others.
          </Typography>

          <Typography className="terms-sub-head2">Purpose</Typography>
          <Typography className="terms-para">
            From time to time, the Company may gather personal data from you for
            a number of purposes, including: (i) maintenance, administration and
            monitoring of this these Services; (ii) following up on enquiries,
            comments and other messages that you submit to the Company through
            these Services; (iii) facilitating the administration and delivery
            of services and products to you; (iv) identity verification,
            matching and/or records; (v) compiling aggregate statistics of the
            use of these Services; (vi) marketing and promotional efforts by or
            on behalf of the Company; (vii) liaising and communicating with
            government departments, regulators or agencies; (viii) fulfilling
            any other purposes relating to the Company's business and
            activities; and (ix) transferring personal data in connection with
            any of the abovementioned purpose(s).
          </Typography>

          <Typography className="terms-sub-head2">
            Disclosure of Personal Data
          </Typography>
          <Typography className="terms-para">
            The Company will keep all personal data confidential but may
            disclose the personal data to the following parties for the
            abovementioned purpose(s): (i) any of its parent, subsidiaries and
            affiliated companies, business partners and professional advisers in
            connection with the operation of its business and activities; (ii)
            any agent, contractor or third party who provides administrative,
            installation, telecommunication, computer, payment, insurance,
            financing, information or other services to any of its group
            company; (iii) any person under a duty of confidentiality to the
            Company or who has undertaken to keep such data confidential; (iv)
            any person to whom the Company is required by applicable legal,
            governmental or regulatory requirements to make disclosure; and (v)
            any actual or proposed assignee, purchaser or subscriber of all or
            part of the business of the Company or participant or
            sub-participant or transferee of the Company's rights.
          </Typography>

          <Typography className="terms-text-big">
            How Information About You Is Shared
          </Typography>
          <Typography className="terms-para">
            When you use the Services, certain information may be shared with
            other users and the public. For example:
            <br />
            <br />
            When you submit content (such as a post or comment or public chat)
            to the Services, any visitors to and users of our Services will be
            able to see that content, the username associated with the content,
            and the date and time you originally submitted the content. The
            Services may allow other websites to embed public content via our
            embed tools. The Services also allows third parties to access public
            ChopeShift & CHuGO content via the ChopeShift & CHuGO API and via
            other similar technologies.
            <br />
            <br />
            Although some parts of the Services may be private or quarantined,
            they may become public (e.g., at the moderator's option in the case
            of private communities) and you should take that into consideration
            before posting to the Services.
            <br />
            <br />
            We offer social sharing features that let you share content or
            actions you take on our Services with other media. Your use of these
            features enables the sharing of certain information with your
            friends or the public, depending on the settings you establish with
            the third party that provides the social sharing feature. For more
            information about the purpose and scope of data collection and
            processing in connection with social sharing features, please visit
            the privacy policies of the third parties that provide these social
            sharing features (e.g., Instagram, Facebook, and Twitter).
            <br />
            <br />
            Please note that, even when you delete your account, the posts,
            comments and messages you submit through the Services may still be
            viewable or available on our servers.
            <br />
            <br />
            Otherwise, we do not share, sell, or give away your personal
            information to third parties unless one of the following
            circumstances applies:
            <br />
            <br />
            <span className="terms-italic">With linked services.</span> If you
            link your ChopeShift and/or CHuGO account/s with a third-party
            service, ChopeShift and/or CHuGO will share the information you
            authorize with that third-party service.
            <br />
            <br />
            <span className="terms-italic">With our partners.</span> We may
            share information with vendors, consultants, and other service
            providers (but not with advertisers and ad partners) who need access
            to such information to carry out work for us. The partner's use of
            personal data will be subject to appropriate confidentiality and
            security measures.
            <br />
            <br />
            <span className="terms-italic">To comply with local law.</span> We
            may share information in response to a request for information if we
            believe disclosure is in accordance with, or required by, any
            applicable law, regulation, legal process or governmental request,
            including, but not limited to, meeting national security or law
            enforcement requirements in the Republic of Singapore. To the extent
            the law allows it, we will attempt to provide you with prior notice
            before disclosing your information in response to such a request.
            <br />
            <br />
            <span className="terms-italic">
              To enforce our policies and rights.
            </span>{" "}
            We may share information if we believe your actions are inconsistent
            with our user agreements, rules, or other Services policies, or to
            protect the rights, property, and safety of ourselves and others.
            <br />
            <br />
            <span className="terms-italic">With our affiliates.</span> We may
            share information between and among our Services, and any of our
            parents, affiliates, subsidiaries, and other companies under common
            control and ownership.
            <br />
            <br />
            <span className="terms-italic">With your consent.</span> We may
            share information about you with your consent or at your direction.
            <br />
            <br />
            <span className="terms-italic">
              Aggregated or de-identified information.
            </span>{" "}
            We may share information about you that has been aggregated or
            anonymized such that it cannot reasonably be used to identify you.
            For example, we may show the total number of times a post has been
            upvoted without identifying who the visitors were.
          </Typography>

          <Typography className="terms-sub-head2">
            Access and Correction of Personal Data{" "}
          </Typography>
          <Typography className="terms-para">
            Under the Ordinance, you have the right to ascertain whether the
            Company holds your personal data, to obtain a copy of the data, and
            to correct any data that is inaccurate. You may also request the
            Company to inform you of the type of personal data held by the
            Company. Minors under the age of 18 are not allowed to create an
            account
            <br />
            <br />
            Requests for access and correction or for information regarding
            policies and practices and kinds of data should be addressed in
            writing to: chopeshift@challenger.sg
            <br />
            <br />A reasonable fee may be charged to offset our administrative
            and actual costs in complying with your access requests. The Company
            reserves the right to amend this Privacy Policy at its sole
            discretion at any time without prior notice.
          </Typography>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

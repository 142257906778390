import React, { Component } from "react";
import "./MenuDrawer.scss";
import { ButtonRed } from "../../Globals";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import WorkOutline from "@mui/icons-material/WorkOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import Collapse from "@mui/material/Collapse";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default class MenuDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false,
      open: false,
    };
  }
  async componentDidMount() {}
  openDrawerMethod = () => {
    this.setState({ openDrawer: true });
  };
  closeDrawerMethod = () => {
    this.setState({ openDrawer: false });
  };
  handleClick = () => {
    const open = this.state.open;
    this.setState({ open: !open });
  };
  list = (anchor) => (
    <Box role="presentation" className="MenuDrawer">
      <List>
        <ListItem class="logo-container center">
          <IconButton onClick={this.closeDrawerMethod} className="close-button">
            <CloseIcon fontSize="large" />
          </IconButton>
          <img className="logo" src="assets/images/cropped-cropped-chugo_color.png" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={this.handleClick}>
          <ListItemText primary="Our Brands" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => this.props.handleItemClick("https://chugosoftware.com/chopeshift")}
            >
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText primary="ChopeShift" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => this.props.handleItemClick("https://lexisjobs.asia")}
            >
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText primary="LexisJobs" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => this.props.handleItemClick("https://www.lexiscard.asia")}
            >
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText primary="LexisCard" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText primary="Chugo (comming soon!)" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText primary="ChopeBoss (comming soon!)" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={() => this.props.handleUrl("/OurMission")}>
          <ListItemText primary="Our Mission" />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText>
            <ButtonRed
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => this.props.handleUrl("/ContactUs")}
            >
              ContactUs
            </ButtonRed>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );

  render() {
    return (
      <Drawer
        anchor={"left"}
        open={this.state.openDrawer}
        onClose={this.closeDrawerMethod}
        className="mainDrawer"
      >
        {this.list()}
      </Drawer>
    );
  }
}

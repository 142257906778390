import BasePageComponent from "../../components/BasePageComponent";
import { GridContainer } from "../../Globals";
import CardItem from "../../components/cards/CardItem";
import { Box, Button, Grid, Typography } from "@mui/material";
import "./Home.scss";

export default class Home extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {};
    setTimeout(()=>{
      window.location.href = 'https://www.chugo.asia';
    },500)
  }
  handleContactUs = () => {
    window.location.href = "/ContactUs";
  };

  render() {
    const cards_data = [
      {
        img_url: "/assets/images/chopeshift-logo.png",
        description: "Flexible part-time jobs that suit your needs and preferences",
        link: "https://www.chopeshift.sg",
      },
      {
        img_url: "/assets/images/Lexisjobs-logo.png",
        description: "Find your perfect job match or job candidate with just one click",
        link: "https://lexisjobs.asia/",
      },
      {
        img_url: "/assets/images/Lexiscard_logo.png",
        description: "Highly convenient all-in-one name card storage and issuance app",
        link: "https://lexiscard.asia/",
      },
      {
        img_url: "/assets/images/cropped-cropped-chugo_color.png",
        description: "Outsource your HR processes to us so you can focus on building your business",
        link: null,
      },
      {
        img_url: "/assets/images/chopeboss-svg.svg",
        description: "Hire the part-time help you need, when you need it",
        link: null,
      },
    ];

    return super.render(
      <div className="home">
        <div className="banner">
          <div className="container">
            <div className="banner_content">
              <Typography variant="h1" className="banner_title">
                Everything you need to succeed, <span className="gradient">all in one place</span>
              </Typography>
              <Typography className="banner_para">
                CHuGO is here to help you thrive in your work. We provide platforms for job
                searching, recruiting, networking. No matter who you are, there is something here
                tailored for you!
              </Typography>
            </div>
            <div className="banner_image">
              <img
                src="../../../../assets/images/chugo-hero-brand-mockups.png"
                className="bannerImg"
              />
              <img
                src="../../../../assets/images/chugo-mobilehero-brand-mockups.png"
                className="bannerImg2"
              />
            </div>
          </div>
        </div>

        <div className="ourPlatform">
          <div className="container">
            <div className="sec_title">
              <Typography variant="h2">
                Our <span className="gradient">Platforms</span>
              </Typography>
            </div>
            <div className="card-container">
              {cards_data.map((items) => {
                return <CardItem dataIn={items} />;
              })}
            </div>
          </div>
        </div>

        <div className="chugosAims">
          <div className="container">
            <div className="sec_title">
              <Typography variant="h2">
                Chugo's <span className="gradient">Aims</span>
              </Typography>
            </div>
            <Box className="sec3_contain_content">
              <Grid container spacing={2} className="sec3_grid_container">
                <Grid item xs={6} className="sec3_bulb">
                  <img
                    src="../../../../assets/images/light-bulb-3d-illustration-compressed.png"
                    className="sec3_image"
                  />
                </Grid>
                <GridContainer item xs={6} className="sec3_content_holder">
                  <Typography variant="h5" className="sec3_title">
                    Simplify Complex Processes
                  </Typography>
                  <Typography classname="sec3_para">
                    Don't let complex processes intimidate you! Let CHuGO take care of it! We break
                    down these monsters into easy digestible bits - making them more manageable than
                    ever before.
                  </Typography>
                </GridContainer>
              </Grid>
            </Box>
            <Box className="sec3_contain_content thumbsUp">
              <Grid container spacing={2} className="sec3_grid_container">
                <GridContainer item xs={6} className="sec3_content_holder">
                  <Typography variant="h5" className="sec3_title">
                    Create Exquisite and Meaningful Platforms
                  </Typography>
                  <Typography classname="sec3_para">
                    We’re committed to creating platforms that meet your needs and exceed your
                    expectations. Our interest is always focused on how we can serve you better than
                    before!
                  </Typography>
                </GridContainer>
                <Grid item xs={6} className="sec3_thumbs_up">
                  <img
                    src="../../../../assets/images/thumbs-up-3d-illustration-compressed.png"
                    className="sec3_image"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="sec3_contain_content">
              <Grid container spacing={2} className="sec3_grid_container">
                <Grid item xs={6} className="sec3_crowned">
                  <img
                    src="../../../../assets/images/crown-3d-illustration-compressed.png"
                    className="sec3_image"
                  />
                </Grid>
                <GridContainer item xs={6} className="sec3_content_holder">
                  <Typography variant="h5" className="sec3_title">
                    Pioneer Industry Changes
                  </Typography>
                  <Typography classname="sec3_para">
                    Just as the world is ever-changing, HR processes should grow accordingly too! At
                    CHuGO, we’re constantly ideating and improving on current approaches to inspire
                    change in the industry.
                  </Typography>
                </GridContainer>
              </Grid>
            </Box>
          </div>
        </div>

        <div className="GetInTouch">
          <div className="container">
            <Box className="getInTouchHolder">
              <Grid
                container
                spacing={2}
                sx={{
                  backgroundColor: "transparent",
                  backgroundImage: "linear-gradient(90deg,#4900ff 0%,#af99ff 100%)",
                  borderRadius: 10,
                }}
                className="sec4_banner"
              >
                <GridContainer item xs={6} className="sec4_content_holder">
                  <Typography
                    variant="h5"
                    className="sec4_title"
                    sx={{
                      fontSize: 43,
                      lineHeight: "49px",
                      color: "#ffffff",
                    }}
                  >
                    Get In Touch with Us!
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 22,
                      lineHeight: "28px",
                      color: "#ffffff",
                      marginBottom: 5,
                    }}
                    className="gettouch_para"
                  >
                    Ready to join our job searching and networking platforms? Drop us a message and
                    we will get back to you as soon as possible!
                  </Typography>
                  <Button
                    onClick={this.handleContactUs}
                    sx={{
                      backgroundColor: "transparent",
                      backgroundImage: "linear-gradient(90deg,#ec4067 15%,#ffc300 100%)",
                      color: "#ffffff",
                      borderRadius: 5,
                      padding: 1.4,
                    }}
                    className="btnContactUs"
                  >
                    Contact Us
                  </Button>
                </GridContainer>
                <Grid item xs={6} className="sec4_image">
                  <img
                    src="../../../../assets/images/rocket-illustration-compressed.png"
                    className="getInTouchImg"
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

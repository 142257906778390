import React, { Component } from "react";
import { ButtonRed } from "../../Globals";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import WorkOutline from "@mui/icons-material/WorkOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import Collapse from "@mui/material/Collapse";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default class BurgerNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleClick = () => {
    const open = this.state.open;
    this.setState({ open: !open });
  };
  async componentDidMount() {}

  render() {
    const colapseOpen = this.state.open;
    return (
      <Collapse in={this.props.display} timeout="auto" unmountOnExit>
        <Box role="presentation" className="MenuDrawer">
          <List>
            <ListItemButton onClick={this.handleClick}>
              <ListItemText className="burgerNavs" primary="Our Brands" />
              {colapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Divider />
            <Collapse in={colapseOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => this.props.handleItemClick("https://www.chopeshift.sg")}
                >
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText className="burgerNavs" primary="ChopeShift" />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => this.props.handleItemClick("https://lexisjobs.asia")}
                >
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText className="burgerNavs" primary="LexisJobs" />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => this.props.handleItemClick("https://www.lexiscard.asia")}
                >
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText className="burgerNavs" primary="LexisCard" />
                </ListItemButton>
                <Divider />
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText className="burgerNavs" primary="Chugo (coming soon!)" />
                </ListItemButton>
                <Divider />
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText className="burgerNavs" primary="ChopeBoss (coming soon!)" />
                </ListItemButton>
              </List>
              <Divider />
            </Collapse>
            <ListItem button onClick={() => this.props.handleUrl("/OurMission")}>
              <ListItemText className="burgerNavs" primary="Our Mission" />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText className="burgerNavs">
                <ButtonRed
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.handleUrl("/ContactUs")}
                >
                  Contact Us
                </ButtonRed>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Collapse>
    );
  }
}

import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, Box, CardActions } from "@mui/material";

export default class CardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const data = this.props?.dataIn;

    return (
      <>
        <Card className="cardItem">
          <Box sx={{ maxHeight: 100 }}>
            <CardMedia
              component="img"
              image={data?.img_url}
              alt="green iguana"
              sx={{ width: 250, margin: "0 auto", paddingTop: 5, paddingBottom: 2 }}
              className="card_img"
            />
          </Box>
          <CardContent
            sx={{
              minHeight: 70,
              maxHeight: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "center" }}
              className="card_para"
            >
              {data?.description}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "center", padding: 3 }}>
            {data?.link ? (
              <Button href={data?.link} size="small" className="card_btn">
                Learn more
              </Button>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textAlign: "center" }}
                className="card_btn"
              >
                Coming Soon!
              </Typography>
            )}
          </CardActions>
        </Card>
      </>
    );
  }
}

export const URL = () => {
  switch (window.location.host) {
    case "localhost:3001":
      return "http://localhost:3000";
      break;
    case "dev-web.chugo.sg":
      return "https://dev-web.chugo.sg/api";
      break;
    default:
      return "https://www.chugo.sg/api";
      break;
  }
};

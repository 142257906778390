import React, { Component } from "react";
import { ButtonBlack, ButtonRed } from "../../Globals";
import { styled, alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginLeft: 110,
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default class HeaderNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const component = this.props.componentProps;
    return (
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={component.anchorEl}
        open={component.open}
        onClose={component.handleClose}
        onMouseLeave={component.handleClose}
      >
        <MenuItem
          className="burgerNavs"
          onClick={() => component.handleItemClick("https://www.chopeshift.sg")}
          disableRipple
        >
          ChopeShift
        </MenuItem>
        <MenuItem
          className="burgerNavs"
          onClick={() => component.handleItemClick("https://lexisjobs.asia")}
          disableRipple
        >
          LexisJobs
        </MenuItem>
        <MenuItem
          className="burgerNavs"
          onClick={() => component.handleItemClick("https://www.lexiscard.asia")}
          disableRipple
        >
          LexisCard
        </MenuItem>
        <MenuItem className="burgerNavs" onClick={() => component.handleItemClick()} disableRipple>
          Chugo (coming soon!)
        </MenuItem>
        <MenuItem className="burgerNavs" onClick={() => component.handleItemClick()} disableRipple>
          ChopeBoss (coming soon!)
        </MenuItem>
      </StyledMenu>
    );
  }
}

import BasePageComponent from "../../components/BasePageComponent";
import { Box, Button, Grid, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactForm from "../../components/contactForm/ContactForm";
import "./ContactUs.scss";

export default class ContactUs extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return super.render(
      <div className="contactUs">
        <div className="container">
          <div className="headline">
            <Typography variant="h2" className="headline_title">
              Let's <span className="gradient">Get In Touch</span>
            </Typography>
            <Typography className="headline_para">
              Feel free to contact us to discuss about how our services can meet your needs. We will
              get back to you as soon as possible.
            </Typography>
          </div>

          <div className="contact_section">
            <div className="container">
              <div className="contactInfo">
                <div className="contact_title">
                  <Typography variant="h3" className="contact-info">
                    Contact Information
                  </Typography>
                </div>
                <div className="contact-information">
                  <div className="contact_content">
                    <div className="contact_icon">
                      <div className="icon_holder">
                        <LocalPhoneIcon sx={{ color: "#ffffff" }} />
                      </div>
                    </div>
                    <div className="contact_data">
                      <Typography>
                        <b>Call Us</b>
                        <br />
                        +65 6318 9800
                      </Typography>
                    </div>
                  </div>
                  <div className="contact_content">
                    <div className="contact_icon">
                      <div className="icon_holder">
                        <EmailIcon sx={{ color: "#ffffff" }} />
                      </div>
                    </div>
                    <div className="contact_data">
                      <Typography>
                        <b>Email Us</b>
                        <br />
                        contact@chugo.sg
                      </Typography>
                    </div>
                  </div>
                  <div className="contact_content">
                    <div className="contact_icon">
                      <div className="icon_holder">
                        <LocationOnIcon sx={{ color: "#ffffff" }} />
                      </div>
                    </div>
                    <div className="contact_data">
                      <Typography>
                        <b>Address</b>
                        <br />1 Ubi Link Challenger TecHub Singapore 408553
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="contact_map">
                  <iframe
                    width="100%"
                    height="300"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=1%20Ubi%20Link,%20Singapore%20408553+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
              <div className="contact_form">
                <ContactForm />
              </div>
            </div>
          </div>

          <div className="brands">
            <Typography className="brand_title">Discover Our Brands</Typography>
            <div className="logos">
              <a href="https://www.chopeshift.sg">
                <img src="/assets/images/chopeshift-logo.png" width="150" />
              </a>
              <a href="https://lexisjobs.asia">
                <img src="/assets/images/Lexisjobs-logo.png" width="150" />
              </a>
              <a href="https://www.lexiscard.asia">
                <img src="/assets/images/Lexiscard_logo.png" width="150" />
              </a>
              <img src="/assets/images/cropped-cropped-chugo_color.png" width="150" />
              <img src="/assets/images/chopeboss-svg.svg" width="150" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

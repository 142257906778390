import React, { Component } from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import "./BasePageComponent.scss";
// import 'typeface-roboto'
import "typeface-inter";
import BurgerNav from "./burgerNav/BurgerNav";

export default class BasePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerNav: false,
    };
  }
  handleUrl = (url) => {
    window.location.href = url;
  };
  handleItemClick = (link = "") => {
    if (link !== "") {
      window.location = link;
    }
  };
  menuClick = () => {
    console.log("working");
    const display = this.state.burgerNav;
    this.setState({ ...this.state, burgerNav: !display });
  };
  async componentDidMount() {}

  render(Page) {
    const burgerNav = this.state.burgerNav;
    return (
      <>
        <Header
          menuClick={this.menuClick}
          handleItemClick={this.handleItemClick}
          navDisplay={burgerNav}
        />
        <div class="burgerContainer">
          <BurgerNav
            display={this.state.burgerNav}
            handleUrl={this.handleUrl}
            handleItemClick={this.handleItemClick}
          />
        </div>

        {Page}
        <Footer />
      </>
    );
  }
}

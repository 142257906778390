import * as React from "react";
import BasePageComponent from "../../components/BasePageComponent";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import {
  Card,
  Box,
  CardContent,
  Grid,
  TextField,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import { URL } from "./Url";

const Axios = axios.create({
  baseURL: URL(),
});

export default class ContactForm extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      captcha: "",
      random_captcha: "",
    };
  }

  componentDidMount() {
    this.getRecaptcha();
  }

  getRecaptcha = async () => {
    const res = await Axios.get(`/customCaptcha`);
    const random = res.data.random;

    if (random !== "") {
      this.setState({
        random_captcha: random,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const params = {
      name: data.get("name"),
      email: data.get("email"),
      subject: data.get("subject"),
      message: data.get("message"),
      captcha: data.get("captcha"),
    };

    Axios.post("/sendEmail", params)
      .then((resp) => {
        const response = resp.data.result;
        if (response === "success") {
          this.getRecaptcha();
          this.setState({
            name: "",
            email: "",
            subject: "",
            message: "",
            captcha: "",
          });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const captcha = this.state.random_captcha;
    return (
      <Card className="form-container">
        <form onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
          <CardContent>
            <Typography variant="h4" className="form-title">
              Send Us A Message
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ marginBottom: 1 }}>
                <TextField
                  value={this.state.name}
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: 1 }} className="form_email">
                <TextField
                  value={this.state.email}
                  error={false}
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  helperText="Please enter a valid email address."
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: 1 }}>
                <TextField
                  value={this.state.subject}
                  required
                  fullWidth
                  id="subject"
                  label="Subject"
                  name="subject"
                  onChange={(e) => this.setState({ subject: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: 1 }}>
                <TextField
                  value={this.state.message}
                  required
                  fullWidth
                  id="message"
                  multiline
                  maxRows={10}
                  minRows={7}
                  label="Message"
                  name="message"
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: 1 }} className="form-control">
                {captcha ? (
                  <div className="captcha_holder">
                    <div className="captcha_label">
                      <h6>{captcha}</h6>
                    </div>
                    <Button
                      className="captcha_btn"
                      variant="text"
                      size="small"
                      onClick={() => this.getRecaptcha()}
                    >
                      Regenerate
                    </Button>
                  </div>
                ) : null}

                <TextField
                  placeholder="Enter the code above"
                  id="captcha"
                  name="captcha"
                  type="text"
                  className="input"
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& >fieldset": {
                        borderColor: "#c0c0c0",
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& >fieldset": {
                        borderColor: "#1a67d2",
                      },
                    },
                  }}
                  fullWidth
                  value={this.state.captcha}
                  onChange={(e) => this.setState({ captcha: e.target.value })}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Button type="submit" size="small" className="submitButton">
              Send Message
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  }
}

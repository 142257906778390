import React, { Component } from "react";
import "./Footer.scss";

export default class PageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
    };
  }
  async componentDidMount() {
    const path = window.location.pathname;
    const new_path = path.replace("/", "");
    this.setState({ active: new_path });
  }

  render() {
    const activeLink = this.state.active;
    const OurMission = activeLink === "OurMission" ? "Active" : null;
    const Terms = activeLink === "TermsConditions" ? "Active" : null;
    const Privacy = activeLink === "PrivacyPolicy" ? "Active" : null;
    return (
      <>
        <div className="Footer">
          <div className="container">
            <div className="top center holder">
              <div className="column">
                <img className="logo" src="assets/images/cropped-cropped-chugo_color.png" />
                <p className="footer_paragraph">UEN 202131359H</p>
                <p className="footer_paragraph">© CHuGO 2022</p>
              </div>
              <div className="column empty-column"></div>
              <div className="column">
                <div className="header-text">Our Brands</div>
                <div className="sub-text">
                  <a className="sub-link" href="https://www.chopeshift.sg">
                    ChopeShift
                  </a>
                </div>
                <div className="sub-text">
                  <a className="sub-link" href="https://lexisjobs.asia">
                    LexisJobs
                  </a>
                </div>
                <div className="sub-text">
                  <a className="sub-link" href="https://www.lexiscard.asia">
                    LexisCard
                  </a>
                </div>
                <div className="sub-text">
                  <a className="sub-link" href="#">
                    Chugo (coming soon!)
                  </a>
                </div>
                <div className="sub-text">
                  <a className="sub-link" href="#">
                    ChopeBoss (coming soon!)
                  </a>
                </div>
              </div>
              <div className="column">
                <div className="header-text">Company</div>
                <div className="sub-text">
                  <a className={"sub-link " + OurMission} href="/OurMission">
                    Our Mission
                  </a>
                </div>
                <div className="sub-text">
                  <a className={"sub-link " + Terms} href="/TermsConditions">
                    Terms & Conditions
                  </a>
                </div>
                <div className="sub-text">
                  <a className={"sub-link " + Privacy} href="/PrivacyPolicy">
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalContext } from "./Globals";

const App = (props) => {
  const [appConst, setAppConst] = useState({});
  useEffect(() => {
    setAppConst(props);
  }, []);

  return (
    <div>
      <GlobalContext.Provider value={appConst}>
        <Router>
          <Routes />
        </Router>
      </GlobalContext.Provider>
    </div>
  );
};

export default App;

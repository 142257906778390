import BasePageComponent from "../../components/BasePageComponent";
import "./PrivacyPolicy.scss";
import { Typography } from "@mui/material";

export default class PrivacyPolicy extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return super.render(
      <div className="privacy">
        <div className="container">
          <Typography className="priva-header">Privacy Policy</Typography>
          <Typography className="priva-sub-head">CHuGO Pte Ltd</Typography>

          <Typography className="priva-sub-head2">Privacy Policy</Typography>

          <Typography className="priva-sub-head2">
            Help us create a better online environment for our users.
          </Typography>
          <Typography className="priva-para">
            To create a better online user experience, we need your help by providing us with
            various kinds of information to help us serve you better, and ultimately improve across
            the various brands (CHuGO, ChopeShift, ChopeBoss, LeXisCard.Asia, LexisJobs.Asia (hereby
            known as our “Services”)) within the CHuGO ecosystem, for everyone. Below you'll find an
            explanation of some of the reasons we request information from you, as well as some
            details about how we obtain and use that information. Throughout this Privacy Policy,
            where we refer to your “personal information” means any information you provide to us
            about you. This includes your national registration identity card (NRIC) number, foreign
            identification number (FIN), passport number, name, postal address, email address, date
            of birth and telephone number.
          </Typography>

          <Typography className="priva-sub-head2">
            Where we collect your personal information:{" "}
          </Typography>
          <Typography className="priva-para">
            This section contains the disclaimers and terms of use applicable to the use of
            Chopeshift and CHuGO websites and mobile applications (hereby known as the “Services”),
            governed by the rules and regulations of Challenger Technologies Limited (hereby known
            as the “Company”). By accessing these Services and any of the pages/links, you are
            representing that you have understood and accepted the disclaimers and the terms of use
            set out herein. If you do not accept the terms of use set out herein, you should not
            access these Services.
          </Typography>

          <Typography className="priva-sub-head2">Customer relations</Typography>
          <Typography className="priva-para">
            For a customer relations representative to respond to your questions or concerns, we may
            need personal information such as your name, email address and telephone number. This
            information is used to answer your queries as completely and thoroughly as possible.
          </Typography>

          <Typography className="priva-sub-head2">Account Registration</Typography>
          <Typography className="priva-para">
            When you register an account for any or all our Services, we use the particulars you
            provide to ensure you enjoy the full benefits and keep you to date with the important
            announcements. These may include secured access to our Services and an easy way to
            identify yourself as a registered account user when contacting us.
          </Typography>

          <Typography className="priva-sub-head2">Contest</Typography>
          <Typography className="priva-para">
            When you participate in a contest, we may ask for personal information for us to
            identify and verify the winner of our contest. Regardless the reasons we collect your
            personal information for, we promise only to use it in the ways set out in this Privacy
            Policy. By providing us with your personal information, you consent to us processing
            this personal information in one or more ways described in this Privacy Policy.
          </Typography>

          <Typography className="priva-sub-head2">
            Information that we collect automatically:
          </Typography>
          <Typography className="priva-para">
            Many web surfers are concerned about their privacy and the use of “cookies” on the
            Internet. Cookies are small files that can be used to store information you have already
            provided. Cookies cannot be used to “steal” information about you or your computer
            system. Our Services use cookies to remember your preferences. For example, the website
            may remember which page you viewed, to make your next visit more convenient.
            Nevertheless, cookies can be disabled in your web browser's Internet Options.
          </Typography>

          <Typography className="priva-sub-head2">What we will do with your information</Typography>
          <Typography className="priva-para">
            CHuGO does not sell your personal information to any unconnected third parties.
            <br />
            <br />
            Occasionally, we hire other companies to help us provide some services such as marketing
            assistance. We'll limit the personal information we give them, and they will only have
            access to your personal information for the purposes of performing such functions. They
            are strictly monitored by CHuGO to make certain our customers' privacy is always
            protected.
          </Typography>

          <Typography className="priva-sub-head2">
            Facilitate registered account user benefits
          </Typography>
          <Typography className="priva-para">
            The personal data you provide to us will enable us to (a) track your purchase records,
            (b) update you periodically about relevant programs through which you may enjoy account
            user benefits, (c) update our profiling of customer preferences so that we may be able
            to serve you better, and; (f) provide seamless access to our Services.
          </Typography>

          <Typography className="priva-sub-head2">Marketing</Typography>
          <Typography className="priva-para">
            If you provide any personal information to us, you give us permission to contact you for
            marketing purposes, which may include messages via post, telephone, SMS or email. The
            information you provide us will enable us to (a) track your purchase records, (b) update
            you periodically about relevant programs through which you may enjoy account user
            benefits, (c) update our profiling of customer preferences so that we may be able to
            serve you better. We will ensure that we only send you messages that we think will be of
            interest to you – for instance, details of special offers or information about new
            products and services. If you do not want us to use your personal information to market
            to you, you can decide to opt-out of these types of marketing messages at any time by
            changing the settings in your account settings or by following the unsubscribe link at
            the bottom of any email we send to you.
          </Typography>

          <Typography className="priva-sub-head2">
            Storing your information and keeping it up to date
          </Typography>

          <Typography className="priva-sub-head2">Out of sight, but never out of reach</Typography>
          <Typography className="priva-para">
            When you share your personal information with CHuGO as a registered account user, you
            will always have access to this information. Where you store a “Profile” on the website,
            you can amend the selected details stored in your account yourself. Alternatively, you
            can update, change, or remove any personal contact information & residential address at
            any time by emailing us.
          </Typography>

          <Typography className="priva-sub-head2">Keeping it safe</Typography>
          <Typography className="priva-para">
            We are committed to providing appropriate security controls to protect your personal
            information against foreseeable hazards. Making sure that your personal information is
            secure is very important to CHuGO.
          </Typography>

          <Typography className="priva-sub-head2">
            Change of address? Change of personal information? Let us know
          </Typography>
          <Typography className="priva-para">
            As a registered account user, you can also log in to update your contact number or
            addresses at any time. If you would like us to update your personal information, remove
            your name from our mailing list, or if you have any questions about CHuGO's privacy
            policies or your personal information, simply contact us through our online request form
            here or email to contact@chugo.sg.
            <br />
            <br />
            We will respond to your questions or concerns within 5 working days.
            <br />
            <br />
            Occasionally, we may decide to change this Privacy Policy, so we suggest that you review
            it periodically, so you are aware of any changes.
            <br />
            <br />
            By using this site, you consent to the above privacy policies.
          </Typography>
          <br />
        </div>
      </div>
    );
  }
}
